import React, {useEffect} from "react"
import plan from "../../backend/plan";
import PrivateRoute from "../../router/PrivateRoute";
import Loader from "../../components/Core/Loader";
import {navigate} from "gatsby";

const SubscribePage = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const chargeId = urlParams.get("charge_id");

    if (chargeId) {
      plan.charge(chargeId).then(({status}) => {
        if (status === 200) {
          // todo add success message
        }

        navigate("/account");
      })
        .catch(() => {
          alert("Error! Please try again");
        });
    } else {
      navigate("/account");
    }
  }, []);

  return (
    <Loader/>
  )
};

function subscribe(props) {
  return <PrivateRoute component={SubscribePage} {...props}/>
}

export default subscribe;
